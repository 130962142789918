<template>
  <div class="analysis-wrap">
    <el-row :gutter="12" class="analysis-send-content">
      <el-col :span="8">
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span>可用短信条数</span>
            </div>
          </template>
          <p class="card-content">总{{ canUserMsg }}</p>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span>当月发送条数</span>
            </div>
          </template>
          <p class="card-content">
            成功 {{ summary.month_sent_succeed }} / 失败
            {{ summary.month_sent_failed }}
          </p>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <template #header>
            <div class="card-header">
              <span>当日发送条数</span>
            </div>
          </template>
          <p class="card-content">
            成功 {{ summary.day_sent_succeed }} / 失败
            {{ summary.month_sent_failed }}
          </p>
        </el-card>
      </el-col>
    </el-row>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="ID" label="发送ID"></el-table-column>
      <el-table-column prop="CreatedAt" label="发送时间"></el-table-column>
      <el-table-column prop="CreatedAt" label="发送状态">
        <template #default="scope">
          {{ recordObj[scope.row.Status] }}
        </template>
      </el-table-column>
      <el-table-column prop="Content" label="内容"></el-table-column>
    </el-table>
    <el-pagination
      class="page"
      @current-change="onChangePage"
      v-model:current-page="page"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
import axios from 'axios'
import { RECORD_OBJ } from '@/config/constEnum'
import { defineComponent, ref, onMounted } from 'vue'
export default defineComponent({
  name: 'Analysis',
  setup() {
    const tableData = ref([])
    const total = ref(0)
    const pageSize = 10
    const page = ref(1)
    const canUserMsg = ref(0)
    const summary = ref({})

    const onChangePage = val => {
      page.value = val
      getList()
    }

    const getList = () => {
      const reqData = {
        status: 'all',
        phone: 'all',
        size: pageSize,
        page: page.value
      }
      axios.get('/-/user/stat/history', { params: reqData }).then(res => {
        const data = res.data.msg
        total.value = data.total
        tableData.value = data.smss
      })
    }

    const getSummary = () => {
      axios.get('/-/user/stat/summary').then(res => {
        summary.value = res.data.msg
      })
    }

    const getBalance = () => {
      axios.get('/-/user/finance/balance').then(res => {
        const data = res.data.msg
        canUserMsg.value = data
      })
    }

    onMounted(() => {
      getList()
      getBalance()
      getSummary()
    })
    return {
      total,
      page,
      summary,
      pageSize,
      tableData,
      canUserMsg,
      onChangePage,
      recordObj: RECORD_OBJ
    }
  }
})
</script>
<style lang="less">
.analysis-wrap {
  .analysis-send-content {
    padding-bottom: 20px;
    .card-header {
      font-size: 18px;
      font-weight: 600;
    }

    .card-content {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .page {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
